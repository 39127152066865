import * as ApiHelpers from './api_helper'
const apiRoute = 'roles'
const apiFunctionRoute = 'functions';
const apiUserRoute = 'users';

export const PermissionRepository = {
    GetRolesForRequestAccess: () => ApiHelpers.api_call_get(`${apiRoute}/request_access`),
    GetListRole: (flow) => ApiHelpers.api_call_get(`${apiRoute}${flow ? `?flow=${flow}` : ''}`),
    GetDetail: (id) => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
    PutRole: data => ApiHelpers.api_call_put(apiRoute, data),
    AddRole: data => ApiHelpers.api_call_post(`${apiRoute}`,data),
    DeleteRole: (id) => ApiHelpers.api_call_delete(`${apiRoute}/${id}`),
    GetListUserByRoleId: (id) => ApiHelpers.api_call_get(`${apiRoute}/${id}/users`),
    DeleteUserRole:(roleId, userId) => ApiHelpers.api_call_delete(`${apiRoute}/${roleId}/users/${userId}`),
    GetAllFunctions: (flow) => ApiHelpers.api_call_get(`${apiRoute}/${apiFunctionRoute}/all${flow ? `?flow=${flow}` : ''}`),
    DeleteFunction: (id) => ApiHelpers.api_call_delete(`${apiRoute}/${apiFunctionRoute}/${id}`),
    UpdateFunction: (data) => ApiHelpers.api_call_put(`${apiRoute}/${apiFunctionRoute}`, data),
    GetUsers: (page = 1, pageSize = 20, search = '', onlyOrgHaravan = false) => {
        let path = ApiHelpers.GetApiUrl(`${apiUserRoute}`, { page, pageSize, search, onlyOrgHaravan });
        return ApiHelpers.api_call_get(path);
    },
    GetRoles: (id = 0) => {
        return id ? ApiHelpers.api_call_get(`${apiUserRoute}/${id}/roles`)
            : ApiHelpers.api_call_get(`${apiRoute}`)
    },
    GetUsersBase: (page = 1, pageSize = 20, search = '') => {
        let path = ApiHelpers.GetApiUrl(`${apiUserRoute}/list`, { page, pageSize, search });
        return ApiHelpers.api_call_get(path);
    },
    GetFunctions: (userId) => ApiHelpers.api_call_get(`${apiRoute}/${apiFunctionRoute}?userId=${userId}`),
    SetRolesForUser: (id, data) => ApiHelpers.api_call_post(`${apiUserRoute}/${id}/roles`, data),
    GetFeatures: idSSO => ApiHelpers.api_call_get(`${apiUserRoute}/${idSSO}/features`),
}