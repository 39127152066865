import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {Button, Selection, Notification, Loading,} from '@haravan/react-components'
import { StoreRepository, PermissionRepository } from '../../../../../repositories'
import { closePopup } from '../../../../../components/popup/popup_slice'
import * as HrvComponents from "@haravan/react-components";

export function UpdateKeyAccount() {
    const dispatch = useDispatch()
    const { shopId } = useParams()
    const [listUsers, setListUsers] = useState([])
    const [searchUser, setSearchUser] = useState("")
    const [userId, setUserId] = useState(0)
    const [loading, setLoading] = useState(false);
    useEffect( () => {
        fetchUsers();
    }, [searchUser])
    const fetchUsers = async () => {
        const response = await PermissionRepository.GetUsers(1, 10, searchUser,  true)
        setListUsers(response?.data?.data ?? []);
    };

    const save = () => {
        setLoading(true);
        StoreRepository.UpdateKeyAccount(shopId, userId)
            .then(rs => {
                if (rs.error_message) Notification.error({message: rs.error_message, duration: 3})
                else {
                    Notification.success({message: 'Cập nhật KeyAccount thành công', duration: 2})
                    dispatch(closePopup())
                    setLoading(false);
                    window.location.reload();
                }
                setLoading(false);
            })
    }

    return (
        <div className='shop-user'>
            <div className='class-subscription'>
                <span>Nhân viên</span>
                <Selection showSearch onChange={setUserId} onSearch={(value) => setSearchUser(value)} placeholder='Chọn nhân viên'>
                    <Selection.Option key="0" value={0}>Chọn nhân viên</Selection.Option>
                    {listUsers.map(opt => <Selection.Option key={opt.id} value={opt.id}>{`${opt.id} - ${opt.userName}`}</Selection.Option>)}
                </Selection>
            </div>
            <div className='shop-user-btn'>
                <HrvComponents.Button status="default" onClick={() => dispatch(closePopup())}>
                    Hủy
                </HrvComponents.Button>
                <HrvComponents.Button status="primary"  loading={loading} onClick={() => save()}>
                    Xác nhận
                </HrvComponents.Button>
            </div>
        </div>
    )
}